/* Print Style Sheet */
@media print {
  body {
    background: white;
    color: black;
    margin: 0;
  }

  .ui.top.attached.menu,
  .sideBar,
  .pagination {
    display: none !important;
  }

  .ui.bottom.attached.segment {
    border: none;
  }

  .ui.bottom.attached.segment tfoot {
    display: none !important;
  }

  table, table * {
    border-color: black !important;
  }
}
